import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

const renderIconColor = (type) => {
	if (type === "error") {
		return css`
			fill: ${colors.red};
		`;
	} else if (type === "warning") {
		return css`
			fill: ${colors.secondary};
		`;
	} else if (type === "success") {
		return css`
			fill: ${colors.green};
		`;
	} else {
		return css`
			fill: ${colors.black00};
		`;
	}
};

export const MessageBoxWrapper = styled.div`
	background-color: ${colors.white};
	padding: 30px 15px;
	margin-bottom: 24px;
	border-radius: 5px;
	overflow: hidden;
	@media ${mediaQueryMax.sm} {
		//padding: 15px;
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		@media ${mediaQueryMax.sm} {
			flex-direction: column;
			align-items: flex-start;
			gap: 24px 0;
		}
	}
	.left {
		flex: 1;
		padding: 0 15px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@media ${mediaQueryMax.sm} {
			width: 100%;
		}
		span.icon {
			margin-right: 15px;
			width: 30px;
			display: block;
			@media ${mediaQueryMax.sm} {
				width: 20px;
			}
			span {
				display: flex;
				svg {
					width: 100%;
					height: auto;
					path {
						${(props) => props.type && renderIconColor(props.type)};
					}
				}
			}
		}
		p {
			flex: 1;
			color: ${colors.gray70} !important;
			a {
				color: inherit;
				text-decoration: underline;
			}
		}
	}
	.right {
		padding-right: 5px;
		@media ${mediaQueryMax.sm} {
			padding: 0 15px;
		}
	}
`;
