import React from "react";
import {
	LocationRangeWrapper,
	RangeHandlerThumb,
	RangeHandlerTrack,
	RangeHandlerWrapper,
	RangeValues,
} from "./styled";
import { Range, getTrackBackground } from "react-range";
import { centrosFiltersSet, centrosGet } from "../../../redux/centros/actions";
import { alertShow, appRedirect } from "../../../redux/app/actions";
import { connect } from "react-redux";
import { searchPlacesAutocomplete } from "../../../utils/locations";
import { useIntl } from "gatsby-plugin-intl";
import { colors } from "../../../assets/styles/vars";

const LocationRange = (props) => {
	const {
		centrosFilters,
		centrosFiltersSet,
		centrosGet,
		appRedirect,
		alertShow,
	} = props;

	const handleSearchPlacesAutocomplete = () => {
		searchPlacesAutocomplete(
			centrosFilters,
			centrosGet,
			alertShow,
			appRedirect,
			intl,
		);
	};
	const intl = useIntl();

	const STEP = 25;
	const MIN = 25;
	const MAX = 300;

	const handleSubmit = (e) => {
		e.preventDefault();
		handleSearchPlacesAutocomplete();
	};

	return (
		<LocationRangeWrapper>
			<RangeValues>
				<p>{centrosFilters.distancia} KM</p>
				<span>Proximidad</span>
			</RangeValues>
			<RangeHandlerWrapper>
				<Range
					step={STEP}
					min={MIN}
					max={MAX}
					values={[centrosFilters.distancia]}
					onChange={(values) =>
						centrosFiltersSet({ distancia: values[0] })
					}
					renderTrack={({ props, children }) => (
						<RangeHandlerTrack
							onMouseDown={props.onMouseDown}
							onMouseUp={handleSubmit}
							onTouchStart={props.onTouchStart}
							onTouchEnd={handleSubmit}
						>
							<div
								className="trackInner"
								ref={props.ref}
								style={{
									background: getTrackBackground({
										values: [centrosFilters.distancia],
										colors: [colors.main, colors.gray40],
										min: MIN,
										max: MAX,
									}),
								}}
							>
								{children}
							</div>
						</RangeHandlerTrack>
					)}
					renderThumb={({ props }) => <RangeHandlerThumb {...props} />}
				/>
			</RangeHandlerWrapper>
		</LocationRangeWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		centrosFilters: state.centros.filters,
	};
};

const mapDispatchToProps = {
	centrosFiltersSet,
	centrosGet,
	appRedirect,
	alertShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationRange);
