import React from "react";
import { Link } from "gatsby-plugin-intl";
import { BoxPlaceImgWrapper, BoxText } from "./styled";

const BoxPlaceImg = (props) => {
	const { imgSrc, title, description, url, link } = props;
	return (
		<BoxPlaceImgWrapper>
			<Link to={`/locations/${url}`} />
			<img src={imgSrc} alt={title} />
			<BoxText>
				<h3>{title}</h3>
				<p>{description}</p>
				{link && <span>{link}</span>}
			</BoxText>
		</BoxPlaceImgWrapper>
	);
};

export default BoxPlaceImg;
