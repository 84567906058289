import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const BoxPlaceImgWrapper = styled.div`
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.89) 100%
		);
		opacity: 0.7;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		transition: 0.3s opacity ease-in-out;
	}

	&:hover {
		&:before {
			opacity: 1;
		}
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	> a {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10;
	}
`;

export const BoxText = styled.div`
	padding: 35px;
	z-index: 1;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 10px;
	position: absolute;
	left: 0;
	top: 0;
	@media ${mediaQueryMax.md} {
		padding: 20px 20px 20px 15px;
	}
	h3 {
		color: ${colors.white};
		margin: 0;
	}

	p {
		margin: 0;
		color: ${colors.white};
	}

	span {
		font-size: 16px;
		color: ${colors.main};
		text-decoration: underline;
		@media ${mediaQueryMax.md} {
			font-size: 15px;
		}
	}
`;
