import styled from "styled-components";
import { LocationSearchWrapper } from "../LocationSearch/styled";
import { LocationRangeWrapper } from "../LocationRange/styled";
import { mediaQueryMax } from "../../../assets/styles/vars";

export const LocationSearchFullWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	${LocationSearchWrapper} {
		flex: 0 0 50%;
		max-width: 50%;

		form {
			height: 100%;
			.inputWrapper {
				height: 100%;
				.iconWrapper {
					span.icon {
						width: 30px;
					}
					@media ${mediaQueryMax.lg} {
						span.icon {
							width: 24px;
						}
					}
				}
				input[type="text"] {
					height: 100%;
					font-size: 20px;
					line-height: 22px;
					padding: 16px 65px;
					border-radius: 5px 0 0 5px;
					&::placeholder {
						font-size: 20px;
						line-height: 22px;
					}
				}
			}
		}

		@media ${mediaQueryMax.lg} {
			flex: 0 0 100%;
			max-width: 100%;

			form {
				.inputWrapper {
					input[type="text"] {
						border-radius: 5px 5px 0 0;
						padding: 16px 55px;
					}
				}
			}
		}
		@media ${mediaQueryMax.md} {
			form {
				.inputWrapper {
					input[type="text"] {
						border-radius: 5px;
						box-shadow: 0 0 20px rgba(163, 163, 163, 0.4);
					}
				}
			}
		}
	}
	${LocationRangeWrapper} {
		flex: 0 0 50%;
		max-width: 50%;
		border-radius: 0 5px 5px 0;
		@media ${mediaQueryMax.lg} {
			flex: 0 0 100%;
			max-width: 100%;
			border-radius: 0 0 5px 5px;
		}
		@media ${mediaQueryMax.md} {
			border-radius: 5px;
			background-color: transparent;
		}
	}
`;
