import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const LocationRangeWrapper = styled.div`
	background-color: ${colors.black00};
	border-radius: 5px;
	padding: 15px 15px 15px 35px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	@media ${mediaQueryMax.lg} {
		padding: 15px 10px;
	}
	@media ${mediaQueryMax.md} {
		padding: 15px 0;
	}
`;

export const RangeValues = styled.div`
	p {
		font-weight: 500;
		font-size: 22px;
		line-height: 1.2;
		color: ${colors.white};
	}

	span {
		color: ${colors.gray};
		font-size: 17px;
		line-height: 1.2;
	}
	@media ${mediaQueryMax.sm} {
		order: 1;
	}
`;

export const RangeHandlerWrapper = styled.div`
	//width: calc(100% - 300px);
	flex: 1;
	height: 10px;
	margin: 0 20px;
	box-sizing: content-box;
	@media ${mediaQueryMax.sm} {
		order: 0;
		margin: 0 30px 0 0;
	}
`;

export const RangeHandlerTrack = styled.div`
	height: 7px;
	border-radius: 5px;
	width: 100%;
	overflow: hidden;
	> div.trackInner {
		width: 100%;
		height: 100%;
		align-self: center;
	}
`;

export const RangeHandlerThumb = styled.div`
	height: 22px;
	width: 22px;
	border: 1px solid transparent;
	outline: none;
	border-radius: 50%;
	overflow: hidden;
	background-color: ${colors.gray40};
`;
