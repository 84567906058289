import React from "react";
import { MessageBoxWrapper } from "./styled";
import { ReactSVG } from "react-svg";
import { CustomButton, CustomLink } from "../../../assets/styles/globalCommon";

const MessageBox = (props) => {
	const { icon, type, text, buttonText, buttonUrl, action } = props;

	const renderButton = () => {
		if (action) {
			return (
				<CustomButton onClick={action} className="ghost withUnderline">
					<p>{buttonText}</p>
				</CustomButton>
			);
		} else if (buttonUrl) {
			return (
				<CustomLink to={buttonUrl} className="ghost withUnderline">
					<p>{buttonText}</p>
				</CustomLink>
			);
		}
	};

	return (
		<MessageBoxWrapper type={type}>
			<div className="inner">
				<div className="left">
					<ReactSVG
						src={icon ? icon : "/img/icons/icon-email.svg"}
						wrapper="span"
						className="icon"
					/>
					<p dangerouslySetInnerHTML={{ __html: text }} />
				</div>
				{buttonText && <div className="right">{renderButton()}</div>}
			</div>
		</MessageBoxWrapper>
	);
};

export default MessageBox;
