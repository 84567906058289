import React from "react";
import { LocationSearchFullWrapper } from "./styled";
import LocationSearch from "../LocationSearch";
import LocationRange from "../LocationRange";
import { connect } from "react-redux";
import { useLocation } from "@reach/router";

const LocationSearchFull = ({ isMobile768 }) => {
	const renderSearchFull = () => {
		if (location.pathname === "/resorts") {
			return (
				<>
					{!isMobile768 && <LocationSearch />}
					<LocationRange />
				</>
			);
			// } else if (location.pathname === "/") {
			// 	return (
			// 		<>
			// 			<LocationSearch />
			// 			{!isMobile768 && <LocationRange />}
			// 		</>
			// 	);
		} else {
			return (
				<>
					<LocationSearch />
					<LocationRange />
				</>
			);
		}
	};
	const location = useLocation();

	return (
		<LocationSearchFullWrapper>
			{renderSearchFull()}
		</LocationSearchFullWrapper>
	);
};

const mapStateToProps = (state) => {
	const { isMobile768 } = state.app.global;
	return {
		isMobile768,
	};
};

export default connect(mapStateToProps, null)(LocationSearchFull);
